import React from 'react'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'
import styled from 'styled-components'
import { below, above } from '../styles/abstracts/breakpoints.js'
import Layout from '../containers/layout'
import Helmet from 'react-helmet'
import { FaInstagram, FaFacebookSquare, FaYoutube, FaRegArrowAltCircleLeft } from 'react-icons/fa'
import ContentBlocks from '../components/contentBlocks'

export const query = graphql`
  query InstructorTemplateQuery($slug: String) {
    instructorPage: sanityInstructorPage(slug: { current: { eq: $slug } }) {
      title
      seoDescription
      slug {
        current
      }
      publishedAt
      _rawContentBlocks(resolveReferences: { maxDepth: 10 })
      instructor {
        name
        instructorTitle
        image {
          asset {
            id
            url
          }
        }
        body
        achievements
        facebookURL
        youTubeURL
        instagramURL
        instructorPrograms {
          programType
          programTitle
        }
      }
    }
  }
`

const SeeAllInstructorsBlock = styled.div`
  position: relative;
  display: block;
  background-color: ${props => props.theme.color.grey.dark};
  height: 81px;
  top: 81px;
  padding-left: 50px;
  width: auto;

  ${below.md} {
    height: 68px;
    top: 68px;
    padding-left: 20px;
  }
`

const SeeAllInstructorsWrapper = styled.div`
  position: relative;
  top: calc(50% - 11px);
  transform: translateY(-50% - 11px);
  height: 25px;
`

const ArrowLeftIcon = styled(FaRegArrowAltCircleLeft)`
  float: left;
  padding: 0em;
  padding-top: 0.1em;
  padding-right: 0.5em;
  color: ${props => props.theme.font.color.grey.light};
`

const SeeAllInstructorsText = styled.div`
  float: left;
  font-family: ${props => props.theme.font.family.base};
  font-size: 15px;
  font-weight: bold;
  color: ${props => props.theme.font.color.grey.light};
`

const OuterBioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.color.grey.light};
  padding-left: 135px;
  padding-top: 155px;
  @media (max-width: 965px) {
    flex-direction: column;
  }
  ${below.md} {
    padding-top: 94px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const TextWrapper = styled.div`
  width: 45%;
  overflow-wrap: wrap;

  @media (max-width: 965px) {
    width: 75%;
  }
  ${below.md} {
    width: 100%;
  }
`

const HeaderText1 = styled.h2`
  display: block;
  font-family: ${props => props.theme.font.family.base};
  font-size: 38px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
  color: ${props => props.theme.font.color.grey.dark};
  float: left;
  width: 100%;
`

const HeaderText2 = styled.h3`
  display: block;
  font-size: 21px;
  font-family: ${props => props.theme.font.family.base};
  color: ${props => props.theme.color.black};
  font-weight: bold;
  background-color: ${props => props.theme.color.yellow};
  letter-spacing: -0.77px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  margin-top: 0px;
  margin-bottom: 0px;
  float: left;
`

const HeaderText3 = styled(HeaderText1)`
  margin-top: 65px;
  margin-bottom: 135px;
  @media (max-width: 965px) {
    display: none;
  }
  ${below.md} {
    margin-top: 85px;
  }
  ${below.sm} {
    margin-top: 35px;
  }
`
const HeaderText3Mobile = styled(HeaderText3)`
  @media (max-width: 965px) {
    display: inline-block;
  }
  @media (min-width: 965px) {
    display: none;
  }
`
const IconWrapper = styled.div`
  display: table;
  width: 100%;
  float: left;
  margin-top: 26px;
  margin-bottom: 30px;
`

const FacebookIcon = styled(FaFacebookSquare)`
  float: left;
  display: table-cell;
  padding: 0.5em;
  padding-top: 0.25em;
  vertical-align: middle;
  color: ${props => props.theme.color.black};
`

const InstagramIcon = styled(FaInstagram)`
  float: left;
  display: table-cell;
  padding: 0.5em;
  padding-top: 0.25em;
  vertical-align: middle;
  color: ${props => props.theme.color.black};
`

const YouTubeIcon = styled(FaYoutube)`
  float: left;
  display: table-cell;
  padding: 0.5em;
  padding-top: 0.25em;
  vertical-align: middle;
  color: ${props => props.theme.color.black};
`

const Description = styled.div`
  display: block;
  font-size: ${props => props.theme.font.size.base};
  font-family: ${props => props.theme.font.family.base};
  color: ${props => props.theme.color.grey.medium};
  float: left;
  ${below.md} {
    font-size: 18px;
    line-height: 30px;
  }
`

const AchievementList = styled.ul`
  display: block;
  font-size: ${props => props.theme.font.size.base};
  font-family: ${props => props.theme.font.family.base};
  color: ${props => props.theme.color.grey.medium};
  float: left;
  padding-left: 20px;
  ${below.md} {
    font-size: 18px;
    line-height: 30px;
  }
`

const InstructorImage = styled.div`
  width: 55%;
  background-image: url(${props => props.imageUrl});
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;

  ${below.lg} {
    width: 45%;
  }

  @media (max-width: 965px) {
    width: 70%;
    min-height: 450px;
  }
  ${below.md} {
    width: 80%;
    margin-top: 85px;
  }
  ${below.sm} {
    width: 100%;
    margin-top: 35px;
  }
`

const Achievement = styled.li``

const Bio = props => {
  const {
    name,
    instructorTitle,
    body,
    achievements,
    instructorPrograms,
    facebookURL,
    youTubeURL,
    instagramURL,
    image
  } = props.props.data.instructorPage.instructor[0]
  const achievementsDisplay = achievements.split('\n').map((achievement, i) => {
    return <Achievement key={i}>{achievement}</Achievement>
  })

  return (
    <OuterBioContainer>
      <TextWrapper>
        <HeaderText1>{name}</HeaderText1>
        <HeaderText2>{instructorTitle}</HeaderText2>
        <IconWrapper>
          {facebookURL ? (
            <a href={`${facebookURL}`}>
              <FacebookIcon />
            </a>
          ) : (
            <div />
          )}
          {youTubeURL ? (
            <a href={`${youTubeURL}`}>
              <YouTubeIcon />
            </a>
          ) : (
            <div />
          )}
          {instagramURL ? (
            <a href={`${instagramURL}`}>
              <InstagramIcon />
            </a>
          ) : (
            <div />
          )}
        </IconWrapper>
        <Description>{body}</Description>
        <AchievementList>{achievementsDisplay}</AchievementList>
        {instructorPrograms.length === 0 ? (
          <div />
        ) : (
          <HeaderText3>{name.substr(0, name.indexOf(' '))}'S PROGRAMS</HeaderText3>
        )}
      </TextWrapper>
      {image && <InstructorImage imageUrl={image.asset.url} />}
      {instructorPrograms.length === 0 ? (
        <div />
      ) : (
        <HeaderText3Mobile>{name.substr(0, name.indexOf(' '))}'S PROGRAMS</HeaderText3Mobile>
      )}
    </OuterBioContainer>
  )
}

const InstructorPage = props => {
  const {
    instructorPage,
    instructorPage: { _rawContentBlocks: pageBlocks }
  } = props.data

  const seo = {
    title: instructorPage.title,
    seoDescription: instructorPage.seoDescription,
    slug: instructorPage.slug
  }

  return (
    <Layout seo={seo}>
      <Helmet>
        <link
          href='https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,500,600,700,900'
          rel='stylesheet'
        />
      </Helmet>
      <Link to='/instructors/'>
        <SeeAllInstructorsBlock>
          <SeeAllInstructorsWrapper>
            <ArrowLeftIcon />
            <SeeAllInstructorsText>SEE ALL INSTRUCTORS</SeeAllInstructorsText>
          </SeeAllInstructorsWrapper>
        </SeeAllInstructorsBlock>
      </Link>
      <Bio props={props} />
      <ContentBlocks pageBlocks={pageBlocks} />
    </Layout>
  )
}

export default InstructorPage
